"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
if ('objectFit' in document.documentElement.style === false) {
  document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('img[data-object-fit]').forEach(function (image) {
      (image.runtimeStyle || image.style).background = "url(\"".concat(image.src, "\") no-repeat 50%/").concat(image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));
      image.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(image.width, "' height='").concat(image.height, "'%3E%3C/svg%3E");
    });
  });
}

/* global Storage */
var formLocalStorageKey = "_eso";

// change attribute in some element for example change href in  a
function changeObjAttrybute(elem, attrName, attrVal) {
  elem.attr(attrName, attrVal);
}

// change content in some element
function changeObjText(elem, elemVal) {
  elem.html(elemVal);
}
function getTranslationsFromLang() {
  var currLang = $('body').attr('data-current-lang');
  var ajaxParams = [];
  for (var i = 0; i < arguments.length; i++) {
    ajaxParams.push([arguments[i], currLang]);
  }
  var ajaxParamsJson = JSON.stringify(ajaxParams);
  var translations;
  $.ajax({
    type: 'POST',
    url: '//' + window.location.hostname + '/langTranslation/',
    data: {
      params: ajaxParamsJson
    },
    dataType: 'json',
    cache: false,
    async: false,
    success: function success(result) {
      translations = result;
    },
    error: function error(e) {
      console.log(e);
    }
  });
  return translations;
}
var translations = getTranslationsFromLang('form field warning', 'Menu 3 Link 1', 'form label nonlatin error');
getPhoneNumbers();
function showPageEmail() {
  var footerMail = $('.footer-mail');
  changeObjAttrybute(footerMail, 'href', 'mailto:' + translations['Menu 3 Link 1']);
  changeObjText(footerMail, translations['Menu 3 Link 1']);
}
function getPhoneNumbers() {
  $.ajax({
    type: 'POST',
    url: '//' + window.location.hostname + '/get-phone-number/',
    data: {
      language: $('body').data('current-lang')
    },
    dataType: 'json',
    cache: false,
    success: function success(result) {
      if (result.status === true) {
        showPagePhone(result.numbers);
      } else {
        $('.callus, .NLPhoneDropdown').remove();
      }
    },
    error: function error(e) {
      console.error(e);
    }
  });
}
function showPagePhone(phoneNumbers) {
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0];
    $('.top-phone-link').attr('href', 'tel:' + primaryPhone.raw_number).text(primaryPhone.text);
    $('.top-phone-link-mobile').attr('href', 'tel:' + primaryPhone.raw_number);
    $('.footer-phone').attr('href', 'tel:' + primaryPhone.raw_number).text(primaryPhone.number);
    if (typeof phoneNumbers[1] !== "undefined") {
      var additionalPhone = phoneNumbers[1];
      $('top-phone-link-nl-en').attr('href', 'tel:' + primaryPhone.raw_number);
      $('top-phone-link-nl-de').attr('href', 'tel:' + additionalPhone.raw_number);
      $('.top-phone-link-second').attr('href', 'tel:' + additionalPhone.raw_number).text(additionalPhone.text);
    } else {
      $("#NLDropdonwBtn").attr('href', 'tel:' + primaryPhone.raw_number);
    }
  }
}
$(function () {
  showPageEmail();
  if ((typeof localStorage === "undefined" ? "undefined" : _typeof(localStorage)) === 'object') {
    try {
      localStorage.setItem('localStorage', 1);
      localStorage.removeItem('localStorage');
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem;
      Storage.prototype.setItem = function () {};
    }
  }

  //
  // Languages menu
  //
  languagesMenu();

  // Remove old storage
  if (typeof Storage !== "undefined") {
    var lastDate = localStorage.getItem(formLocalStorageKey + '_date');
    if (lastDate !== null && parseInt(lastDate) + 5 * 24 * 60 * 60 * 1000 < Date.now()) {
      localStorage.removeItem(formLocalStorageKey);
      localStorage.removeItem(formLocalStorageKey + '_date');
    }
  }
});
function languagesMenu() {
  $('#NLDropdonwBtn').click(function () {
    $('#NLDropdonwBtn').toggleClass('active');
  });
  $('.languages .current .wrapper').click(function () {
    $('.languages .list-container').toggleClass('active');
  });
}